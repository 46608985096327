<template>
  <div>
    <h5 v-if="label" style="color : #4F5A6E; font-size: 13px; font-weight: 500 " class="mb-2">  {{  label  }} </h5>
    <h5 v-if="description" style="font-size: 13px; font-weight: 400 " class="mb-2">  {{  description  }} </h5>
    <h4 v-if="labelAvalon" style="font-size : 14px" class="mb-2" :style="`color : ${wsDARKER}`"> {{ $t(labelAvalon) }}</h4>

    <v-sheet
        :color="backgroundColor"
        :light="light"
        class="wsRoundedLight d-flex"
        width="100%"
    >

      <ws-tooltip
          style="width: 100%"
          :is="item.tooltip ? 'ws-tooltip' : 'div' "
          v-for="(item,i) in items" :key="i"
          :text="item.tooltip"
          :top="item.tooltip_top"
      >
        <v-btn

            @click="toggle = item.value"
            :outlined="toggle !== item.value"
            :dark="toggle === item.value"
            :color="color || wsACCENT"
            block
            min-width="24"
            class="noCaps"
            :class="[
              {'leftButton' : i === 0},
              {'middleButton' : i > 0 && i < items.length -1},
              {'rightButton' : i === items.length -1},
              {'px-2' : dense }
          ]"
            elevation="0"
            :width="itemsWidth"  >
          <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
          <ws-icon v-if="item.ws_icon" :color="toggle !== item.value ? wsACCENT : wsWHITE" :icon="item.ws_icon" no-right-margin/>
          <span v-if="item.text">{{  item.text  }}</span>
        </v-btn>
      </ws-tooltip>
    </v-sheet>
  </div>

</template>

<script>
export default {
  name: "wsButtonGroup",
  props : {
    value : {
      type : String,
    },
    items : {
      type : Array,
      default() { return [] }
    },
    label : {
      type : String,
      default : ''
    },
    labelAvalon : {
      type : String
    },
    description : {
      type : String,
      default : ''
    },
    dense : {
      type : Boolean,
      default : false
    },
    light : {
      type : Boolean,
      default : false
    },
    small : {
      type : Boolean,
      default : false
    },
    color : {
      type : String,
    },
    backgroundColor : {
      type : String,
    }
  },
  computed : {
    itemsWidth() {
      let width = 100;
      if ( this.items.length > 0 ) {
        width = (width/this.items.length).toFixed(0)
      }

      return width + '%'
    }
  },
  watch : {
    value() {
      if ( this.value !== this.toggle ) {
        this.toggle = this.value
      }
    },
    toggle() {
      if (this.value !== this.toggle ) {
        this.$emit('input',this.toggle)
      }
    }
  },
  data() {
    return {
      toggle : null
    }
  },
  mounted() {
    if ( this.value ) {
      this.toggle = this.value
    }
  }
}
</script>

<style scoped>
.leftButton {
  border-radius: 4px 0 0 4px;
  font-weight: 500;
  font-size: 13px;
}
.middleButton {
  border-left: unset;
  border-radius: 0;
  font-weight: 500;
  font-size: 13px;
}
.rightButton {
  border-left: unset;
  border-radius: 0 4px 4px 0;
  font-weight: 500;
  font-size: 13px;
}
</style>